<template>
	<div class="ip">
		<!-- 筛选框框 -->
		<div class="filterBox">
			<div class="searchBox">
				<input class="searchTxt" v-model="keywords" placeholder="请输入IP名称或关键词" @keyup.enter="onKeywordsFilter" />
				<a class="searchBtn" @click="onKeywordsFilter"></a>
			</div>

			<!-- 新列表 -->
			<div class="opsBox">
				<!-- 区域 -->
				<div class="opBox">
					<span class="opTitle">{{dict['area'].name}}</span>
					<div class="opItems">
						<span v-for="(item,id) in dict['area'].value" :key='id' :class="[ id == dict['area'].selected? 'opItem active': 'opItem']" data-itp="area" :data-iid="[id]" @click.stop="onSelectItem">{{item.name}}</span>
					</div>
				</div>
				<!-- IP类型 -->
				<div class="opBox">
					<span class="opTitle">{{ dict['type'].name }}</span>
					<div class="opItems">
						<span :ref="setItemRef" v-for="(item,id) in dict['type'].value" :key='id' :class="[ id == dict['type'].id_selected? 'opItem active': 'opItem']" data-itp="type" :data-iid="[id]" @click.stop="onSelectItem">{{item.name}}</span>
					</div>
				</div>
				<div class="opBox" v-if="dict['type'].id_selected!=0">
					<span class="opTitle"></span>
					<!-- IP 二级菜单 -->
					<div class="opItems" v-if="typeof(dict['type']['value']) === 'object'">
						<span v-for="(item,pid) in dict['type']['value'][dict['type'].id_selected]['sub']" :key='pid' :class="[ pid == dict['type'].pid_selected ? 'opItem active': 'opItem']" data-itp="minor" :data-iid="[pid]" @click.stop="onSelectItem">{{item.name}}</span>
					</div>
				</div>

				<!-- 标签 -->
				<div class="opBox">
					<span class="opTitle">{{dict['tag'].name}}</span>
					<div class="opItems tags"  v-if="dict['tag'].value">
						<el-scrollbar :key="tagsUnfold" :always="tagsUnfold" :class="tagsUnfold ? '' : 'scrollbarWrapHidden'"  :wrap-style="{overflowY:tagsUnfold ? 'scroll' : 'hidden'}" >
							<span v-for="(item,id) in dict['tag'].value[dict['type'].id_selected]" :key='id' :class="[ id == dict['tag'].selected? 'opItem active': 'opItem']" data-itp="tag" :data-iid="[id]" @click.stop="onSelectItem">{{item}}</span>
						</el-scrollbar>
					
					</div>
					<span class="unfold_button" @click="tagsUnfold = !tagsUnfold" >{{tagsUnfold ?'收起' :'展开'}}
						<el-icon v-if="tagsUnfold" ><ArrowUpBold /></el-icon>
						<el-icon v-else ><ArrowDownBold /></el-icon>
					</span>
					<!-- <el-icon><ArrowDown /></el-icon> -->
				</div>
				<!-- IP 信息 -->
				<div class="opBox">
					<span class="opTitle">{{dict['info'].name}}</span>
					<span v-for="(opItem,key,index) in dict['info']['value']" :key='opItem'>
						<el-select v-model="dict['info']['value'][key]['selected']" :placeholder="opItem.name" :class="['normal']">
							<el-option v-for="item in opItem.value" :key="item.id" :label="item.name" :data-ip="{id:item.name,label:opItem.name,key}" :value="item.id">
							</el-option>
						</el-select>
					</span>
				</div>

				<!-- 价格 -->
				<div class="opBox" v-show="isBrandSide">
					<span class="opTitle">{{dict['price'].name}}</span>
					<div class="opItems">
						<span v-for="(item,id) in dict['price'].value" :key='id' :class="[ id == priceSelect? 'opItem active': 'opItem']" data-itp="price" :data-iid="[id]" @click.stop="onSelectItem">{{item.name}}</span>
						<span v-for="(inputItem,index2) in  2" :key='index2'>
							<span class="inputBox">
								<span class="iptLeft">￥</span><input ref="priceInput" class="iptInput" :data-itp="price" :data-iid="[index2]" maxlength="6" type="number"  ><span class="iptRight">万</span>
							</span>
							<span class="hyphen" v-if="index2 != 1">—</span>
						</span>

						<button class="confirmBtn" @click.stop="onFilterRange">确定</button>
					</div>
				</div>
			</div>
			
			<el-divider></el-divider>
			<div class="selectedTab">
				<span class="selTitle">已筛选条件></span>
				<div class="selItems">
					<div class="labOpts"  v-if="this.search!=''">
						<div class="labOpt" >关键词：{{this.search}}</div>
						<span class="labDelBtn"  v-on:click="delKeywords"></span>
					</div>
					<div v-for="(item,name,index) in filtered" :key='index' class="labOpts">
						<div class="labTitle">{{item.name}}：</div>
						<div class="labOpt" v-if=' item.value && ( item.tp == "area"|| item.tp == "info")'>{{item.value[item.selected]['name']}}</div>
						<div class="labOpt" v-if='item.value && ( item.tp == "price") '>
							<template v-if =" priceSelect==-1 ">
								<template v-if="ipsData.data.priceDown =='' ">
									{{ipsData.data.priceUp+'万以下'}}
								</template>
								<template v-if="ipsData.data.priceUp =='' ">
									{{ipsData.data.priceDown+'万以上'}}
								</template>
								<template v-if="ipsData.data.priceUp !='' && ipsData.data.priceDown !='' ">
									{{ipsData.data.priceDown+'万-'+ ipsData.data.priceUp+'万'}}
								</template>
								
							</template>
							<template v-else>
								{{item.value[this.priceSelect]["name"] }}
							</template>
							
						</div>
						<div class="labOpt" v-if='item.value && (item.tp == "tag")'>{{item.value[dict['type'].id_selected][item.selected]}}</div>
						<div class="labOpt" v-if='item.value && item.tp == "type"'>{{item.value[item.id_selected]['name']}}</div>
						<div class="labOpt" v-if='item.value && item.tp == "minor"'>{{item.value[item.pid_selected]['name']}}</div>

						<span class="labDelBtn" :data-itp="[item.tp]" :data-key="[typeof(item.key) != 'undefined'?item.key:'']" v-on:click="onDeleteItem"></span>
					</div>
					
					<div v-if='filtered.length > 0' class="labOptDel" v-on:click="onDeleteItem($event,true)">
						一键清除
					</div>
				</div>

			</div>
		</div>
		<!-- 嵌入IP列表 -->
		<Ipitems v-bind:ipsData="ipsData"></Ipitems>
		
	</div>
	<footers />
</template>


<script>
	import { ElMessageBox } from 'element-plus'
	import { ArrowDownBold, ArrowUpBold } from "@element-plus/icons-vue";
	import Ipitems from '@/views/ipdetail/ipItems.vue'
	import router from '@/router'
	import footers from '@/components/foot.vue'
	import useStore from '@/store/index.js'

	export default {
		data() {
			return {
				search: '',
				price: '',
				keywords: '',
				itemRefs: [],
				dict: {
					area: { name: '区域', id: 0, value: [{ id: 0, name: '全部' }, { id: 1, name: '国内' }, { id: 2, name: '国外' }], selected: 0 },
					type: { name: '', id_selected: 0, },
					tag: { name: '', selected: 0 },
					info: {
						name: 'IP信息',
						id: 3,
						value: {
							cooperationType: { name: '合作内容', value: [{ id: 0, name: '全部' }, { id: 1, name: '自媒体' }, { id: 2, name: '营销授权' }, { id: 3, name: '衍生品' }, { id: 4, name: '冠名赞助' }, { id: 5, name: '直播/活动出席' }, { id: 6, name: '代言' }, { id: 7, name: '植入以及其他' }], selected: '' },
							cooperationPurpose: { name: '合作目的', value: [{ id: 0, name: '全部' }, { id: 1, name: '品牌' }, { id: 2, name: '效果' }], selected: '' },
							materialAuditRange: { name: '物料审核周期', value: [{ id: 0, name: '全部' }, { id: 1, name: '一周' }, { id: 2, name: '两周' }, { id: 3, name: '一个月' }, { id: 4, name: '两个月' }, { id: 5, name: '3个月及以上' }], selected: '' },
							cooperationRange: { name: '合作周期', value: [{ id: 0, name: '全部' }, { id: 1, name: '一个月' }, { id: 2, name: '三个月' }, { id: 3, name: '六个月' }, { id: 4, name: '一年之内' }, { id: 5, name: '一年以上' }], selected: '' },
							payType: { name: '付款形式', value: [{ id: 0, name: '全部' }, { id: 1, name: '刊前' }, { id: 2, name: '刊后' }], selected: '' }
						}
					},
					price: { name: '价格', id: 4, value: [{ id: 0, name: '全部' }, { id: 1, name: '30万以下' }, { id: 2, name: '30万-60万' }, { id: 3, name: '60万-100万' }] }
				},
				ipsData: {
					type: 1,
					data: {},
					page: {
						page: 1,
						pageSize: 10,
					}
				},
				range1: ['', ''], //筛选条件的
				isCustomPrice:0,
				priceSelect:0,
				tagsUnfold:false,
				isBrandSide: false,
			}
		},
		components: {
			Ipitems,
			footers,
			ArrowDownBold,
			ArrowUpBold
		},
		computed: {
			// 计算同步已筛选条件 一键清除
			filtered: function() {
				let filtered = Object.keys(this.dict),
					filters = {};
				let result = [];
				// 获取选择的字段 
				filtered.map((tp => {
					if (tp == "area" && this.dict[tp].selected != 0) {
						result.push(Object.assign(this.dict[tp], { tp: tp }))
						filters[tp] = this.dict[tp].selected;
					} else if (tp == "tag" && this.dict[tp].selected != 0) {
						result.push(Object.assign(this.dict[tp], { tp: tp }))
						let tags = this.dict['tag'].value[this.dict['type'].id_selected]
						filters[tp] = tags[this.dict[tp].selected]
					} else if (tp == "type" && this.dict[tp].id_selected != 0) {
						// 第一大类不为空
						result.push(Object.assign(this.dict[tp], { tp: tp }))
						filters["ipTypeLevel1"] = this.dict[tp].id_selected;
						if (this.dict[tp].pid_selected != -1) {
							if (this.dict[tp].pid_selected) {
								var sub = this.dict[tp].value[this.dict[tp].id_selected]['sub']
								filters["ipTypeLevel2"] = sub[this.dict[tp].pid_selected].id;
								result.push({
									tp: "minor",
									name: this.dict[tp].value[this.dict[tp].id_selected]['name'],
									value: sub,
									pid_selected: this.dict[tp].pid_selected
								})
							} 
						}
					} // 第二类不为空
					else if (tp == "price"  && this.priceSelect != 0 ) {   
						result.push(Object.assign(this.dict[tp], { tp: tp }))
						console.log(' _  ')
						if( !this.isCustomPrice){
							let range = this.slitPrice(this.priceSelect,this.dict[tp].value);
							if (parseInt(range[0]) > 0 && this.priceSelect > 0) {
								filters["priceDown"] = range[0];
							}
							if (parseInt(range[1]) > 0 && this.priceSelect > 0) {
								filters["priceUp"] = range[1];
							}
						}else{
							filters["priceDown"] = this.$refs.priceInput[0].value;
							filters["priceUp"] = this.$refs.priceInput[1].value;
						}
						

					} else if (tp == "info") {
						// 获取IP下拉 遍历选择了的列表
						let ip = this.dict['info']['value'],
							ipList = Object.keys(ip);
						ipList.map((item) => {
							if (ip[item].selected != '') {
								filters[item] = ip[item].selected;
								result.push({
									tp: "info",
									name: ip[item]['name'],
									value: ip[item]['value'],
									selected: ip[item].selected,
									key: item
								})
							}
						})

					}
				}))

				if (this.search != '') filters.search = this.search;
				// filters = Object.assign(filters,{page:this.page.page,pageSize:this.page.pageSize});

				// 同步接口 判断两次数据是否相等 不相等就要调用接口  
			
				if (typeof this.ipsData.data == 'undefined') this.ipsData.data = filters;
				if (!this.ifCompare(filters, this.ipsData.data)) {
					console.log("变化了",filters)
					this.ipsData.page.page=1;
					this.ipsData.data = filters;
				}
				

				return result;
			},
		},
		methods: {
			setItemRef(el){
				if (el) {
					this.itemRefs.push(el)
				}
			},
			ifCompare(object1, object2) {
				// 判断两对象是否相等
				var o1keys = Object.keys(object1);
				var o2keys = Object.keys(object2);
				if (o2keys.length !== o1keys.length) return false;
				for (let i = 0; i <= o1keys.length - 1; i++) {
					let key = o1keys[i];
					if (!o2keys.includes(key)) return false;
					if (object2[key] !== object1[key]) return false;
				}
				return true;
			}, 
			onSelectItem(e) {
				let tp = e.target.dataset['itp'],
					iid = e.target.dataset['iid'];
				if (tp == "type") {
					this.dict[tp].id_selected = iid;
					this.dict[tp].pid_selected = -1;
					this.dict['tag'].selected = 0
				} else if (tp == "minor") {
					this.dict["type"].pid_selected = iid;
				} else if (tp == "price") {
					this.priceSelect = iid;  
					this.range1 = this.slitPrice(iid,this.dict[tp].value);
					this.$refs.priceInput[0].value= this.range1[0]
					this.$refs.priceInput[1].value= this.range1[1]
					this.isCustomPrice = 0;
				} else {
					this.dict[tp].selected = iid;
				}
			},
			slitPrice(id,ary){
				// 需要同步价格文本，start end
				ary = ary[id]['name'].split('-');
				let range = ['',''];
				
				ary.map((item, i) => {
					var text = item,
						count = 0;
					var pattern = /万/g;
					if (pattern.test(text)) {
						count = RegExp["$`"];
						if(ary.length==1){
							range[1] = count;
						}else{
							range[i] = count;
						}
						
					}
				})
				return range;
			}, 
			itemChange(e) {// 下拉菜单
				let selected = e.currentTarget.value,
					tp = e.target.dataset['itp'],
					iid = e.target.dataset['iid'];
				this.filter[tp].value[iid].selected = selected;
				console.log(4)
			},
			clearAllSelect() {
				let filters = Object.keys(this.dict);
				filters.map((item) => {
					if (item == "type") {
						this.dict[item]["id_selected"] = 0;
						this.dict[item]["pid_selected"] = -1;
					} else if (item == "minor") {
						this.dict["type"]["pid_selected"] = -1;
					}else if (item == "price") {
						this.range1= ['', ''];
						this.priceSelect=0;
						this.$refs.priceInput[0].value = '';
						this.$refs.priceInput[1].value = '';
					} else if (item == "info") {
						// 遍历列表 
						let ip = this.dict['info']['value'],
							ipList = Object.keys(ip);
						ipList.map((item) => {
							ip[item].selected = ''
						})
					} else {
						this.dict[item]["selected"] = 0;
					}
				})
			},
			delKeywords(){
				this.search = this.keywords = '';
			},
			// 删除
			onDeleteItem(e, delAll) {
				console.log(e);
				console.log(delAll);
				// 删除筛选项 全部删除 info
				let tp = e.target.dataset['itp'],
					key = e.target.dataset['key'];
				if (delAll) {
					this.clearAllSelect()
					this.search = this.keywords = "";
				} else {
					if (tp == "type") {
						this.dict[tp]["id_selected"] = 0;
						this.dict[tp]["pid_selected"] = -1;
					} else if (tp == "minor") {
						this.dict["type"]["pid_selected"] = -1;
					} else if (tp == "info") {
						this.dict[tp]['value'][key]["selected"] = "";
					} else if (tp == "price") {
						this.range1= ['', ''];
						this.priceSelect=0;
						this.$refs.priceInput[0].value = '';
						this.$refs.priceInput[1].value = '';
					} else {
						this.dict[tp]["selected"] = 0;
					}
				}

			}, 
			//确定按钮
			onFilterRange() {
				let val0 = this.$refs.priceInput[0].value;
				let val1 = this.$refs.priceInput[1].value;
				if (val0 == '' && val1 == '') {
					this.store.showToast('请输入价格')
					return
				}
				if (parseInt(val1) < parseInt(val0)) {
					this.store.showToast('结束金额不可小于开始金额')

				} else {
					// 同步range 然后
					
					let filters = JSON.parse(JSON.stringify(this.ipsData.data)) ; 
					if (parseInt(val0) > 0) {
						filters["priceDown"] = val0;
					}
					if (parseInt(val1) > 0) {
						filters["priceUp"] =val1;
					}
					
					
					//判断是否在筛选条件中
					var priceArr =[['','30'],['30','60'],['60','100']];
					var txtarr = [val0,val1].toString()
					var arrIndex = priceArr.findIndex((item)=>{
						return item.toString() == txtarr
					})
					if(arrIndex!=-1){
						this.priceSelect = arrIndex+1;
					}else{
						this.priceSelect =-1;
					}
					this.isCustomPrice +=1;
					
				}
			},

			onKeywordsFilter() {
				// this.ipsData.page.page =1
				this.clearAllSelect();
				this.search = this.keywords;
			}, 
		},
		beforeRouteUpdate(to, from, next) {
			next()
			if (to.query) {
				this.search = this.keywords = to.query.keywords;
			}
		},
		mounted(){
			let ipTypeLevel1 = router.currentRoute.value.query.ipTypeLevel1;
			if (ipTypeLevel1) {
				this.$nextTick(()=>{
					setTimeout(()=>{
						this.itemRefs[ipTypeLevel1].click();
					},100)
				})
			}
		},
		created() {
			this.store = useStore()
			let search = router.currentRoute.value.query.keywords;
			if (search) {
				this.search = this.keywords = search;
			}
			// 获取字典
			this.API.ip_filter_options_dict({}, (res) => {
				if (res.code == 0 && typeof(res.data) === 'object') {
					if (typeof(res.data['type']) === 'object') {
						let value = res.data['type'];
						this.dict['type'] = { name: 'IP类型', value, id_selected: 0, pid_selected: 0 };
					}

					if (typeof(res.data['tag']) === 'object') {
						//这里全部作为第0个
						res.data['tag']['0'] = res.data['tag'].all
						let value = res.data['tag'];
						this.dict['tag'] = { name: '标签', value, selected: 0 };
					}

					if (typeof(res.data['info']) === 'object' && typeof(res.data['info']['cooperation_type']) === 'object') {
						let value = res.data['info']['cooperation_type'];
						value.unshift({ id: 0, name: '全部' });
						this.dict['info']['value']['cooperationType'] = { name: '合作内容', value, selected: '' };
					}
				}
			})
			// 判断是否为品牌方登录，显示价格
			this.isBrandSide = this.store.logins.type === 1;
		}
	}
</script>

<style lang="less">
				.scrollbarWrapHidden{
					.el-scrollbar__bar.is-vertical{
						display: none !important;
					}
				}
	// @highlight: #EA5504;.el-select {--el-select-input-focus-border-color: #EA5504;}

	// .el-input {--el-input-focus-border: @highlight;--el-input-placeholder-color: #606060;}

	// .el-select-dropdown__item.selected {color: @highlight;}

	// .el-select .el-input.is-focus .el-input__inner {border-color: @highlight  !important;}

	// .el-input__inner {border: 1px solid #E3E6EC;}
</style>
<style lang="less" scoped>
	@width: 1340px;@white: #ffffff;.ip {font-family: PingFangSC-Regular, PingFang SC;margin: 16px auto 0 auto;width: @width;.filterBox {width: @width;background: @white;border-radius: 4px;padding: 24px 40px;box-sizing: border-box;.searchBox {background-color: @white;width: 756px;height: 42px;border: 1px solid #CACACA;border-radius: 5px;.searchTxt {width: 653px;height: 100%;border-radius: 4px;padding: 0 14px;box-sizing: border-box; :deep(input) {border: 0;line-height: 42px;height: 42px;}
				}

				.searchBtn {width: 98px;height: 100%;background: #F5630B;border-radius: 4px;float: right;&:after {content: " ";width: 20px;height: 20px;display: block;margin-left: 42px;margin-top: 12px;background: url(../assets/images/ip/search.jpg) no-repeat center;}
				}
			}

			.opsBox {.opBox {line-height: 26px;margin: 23px 0 0;display: inline-flex;font-size: 14px; // /deep/ span{}

					:deep(.el-select) {margin-right: 30px;}

					:deep(.normal) {max-width: 140px;}


					// :deep(.el-input--suffix .el-input__inner) {padding-right: 20px;}

					:deep(.el-input__suffix) {right: 4px;}
				}

				.opTitle {width: 43px;font-size: 14px;font-weight: 400;color: #040404;line-height: 32px;margin-right: 14px;user-select: none;text-align: left;}

				.opItems {display: inline-block;width: 1180px;.confirmBtn {width: 62px;height: 28px;cursor: pointer;;border-radius: 2px;border: 1px solid #9EA4BA;font-size: 14px;font-weight: 400;color: #222222;line-height: 20px;margin-left: 16px;background-color: #ffffff;}

					.hyphen {margin: 0 10px;}
				}
				.tags{max-height: 68px;width: auto;flex: 1;
				}

				.opItem {padding: 3px 7px;font-size: 14px;font-weight: 400;color: #606060;line-height: 14px;text-align: center;margin: 6px 12px 6px 12px;cursor: pointer;user-select: none;border-radius: 2px;display: inline-block;&:hover {color: #F5630B;}

					&.active {background: #F5630B;font-weight: 400;color: #FFFFFF;}
				}
				.unfold_button{
					user-select: none;
					height: 24px;
					line-height: 32px;
					width: 60px;
					position: relative;
					font-size: 14px;
					font-family: PingFangSC-Regular,PingFang SC;
					font-weight: 400;
					color: #F5630B;
					cursor: pointer;
					.el-icon{
						margin-left: 6px;
					}
				}
				.dpItem {width: 120px;font-size: 14px;font-weight: 400;color: #606060;line-height: 32px;margin: 0 12px;border-radius: 2px;border: 1px solid #E3E6EC;padding: 6px 9px;&:focus {option {&:checked {background: #F5630B;}

							&:active {background: #F5630B;}
						}
					}

					option {font-size: 14px;font-weight: 400;color: #606060;line-height: 32px;padding: 6px 9px;margin: 4px 0;}
				}

				.inputBox {width: 92px;height: 29px;line-height: 27px;border-radius: 2px;border: 1px solid #E3E6EC;display: inline-block;.iptLeft {width: 9px;height: 27px;line-height: 27px;font-size: 14px;font-weight: 400;color: #606060;display: inline-block;float: left;text-align: center;}

					.iptInput {width: 50px;height: 27px;line-height: 27px;font-size: 14px;display: inline-block;margin-left: 4px;text-align: center;&::-webkit-outer-spin-button,&::-webkit-inner-spin-button {-webkit-appearance: none !important;margin: 0;}

						&[type="number"] {-moz-appearance: textfield;}
					}

					.iptRight {width: 26px;height: 27px;line-height: 27px;font-size: 14px;background: #EFF2F8;border-radius: 0px 2px 2px 0px;display: inline-block;float: right;text-align: center;}
				}
			}

			.selectedTab {user-select: none;display: flex;.keyw1 {line-height: 20px;}

				.selTitle {height: 20px;width: 95px;font-size: 14px;font-weight: 400;color: #222222;line-height: 20px;}

				.selItems {}

				.labOpts {display: inline-flex;padding-left: 8px;border-radius: 2px;border: 1px solid rgba(37, 42, 62, 0.5);margin-left: 16px;margin-bottom: 10px;height: 20px;.labTitle {font-size: 12px;color: #adb1bb;display: inline-block;height: 18px;line-height: 18px;}

					.labOpt {font-size: 12px;color: #EA5504;padding: 0 7px;display: inline-block;height: 18px;line-height: 18px;}

					.labDelBtn {cursor: pointer;background: url(../assets/images/ip/x.png);height: 18px;width: 20px;display: inline-block;}
				}

				.labOptDel {margin-left: 20px;padding: 3px 10px;display: inline-block;color: #EA5504;height: 20px;border-radius: 2px;border: 1px solid #EA5504;cursor: pointer;}
			}
		}


	}
</style>
